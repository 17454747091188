import { ReactNode, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { CountryCode } from 'libphonenumber-js';
import get from 'lodash.get';
import Head from 'next/head';
import { Controller, Resolver, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  CountryCodeType,
  LocalStorageKey,
  MobileNumberInput,
  getMobilePhoneNumberValidationRule,
  setLocalStorageItem,
  useRouter,
} from '@mwell-healthhub/commons';
import { Button } from '@mwell-healthhub/commons/components/v2';
import { trackEvent, EventName } from '@mwell-healthhub/commons/utils/analytics';

import AuthLayout from '../../components/templates/AuthLayout';
import { Routes } from '../../constants';
import { useRegisterWithPhoneNumber } from '../../hooks';

type Form = {
  country: string;
  phoneNumber: string;
  phoneNumberPrefix: string;
};

enum FormEnum {
  COUNTRY = 'country',
  MOBILE_PHONE_NUMBER = 'phoneNumber',
  MOBILE_PHONE_NUMBER_PREFIX = 'phoneNumberPrefix',
}

const formSchema = yup.object().shape({
  country: yup.string().trim().required(),
  phoneNumber: yup
    .string()
    .when('phoneNumberPrefix', (phoneNumberPrefix) =>
      getMobilePhoneNumberValidationRule(phoneNumberPrefix[0]),
    ),
  phoneNumberPrefix: yup.string().trim().required(),
});

export default function AuthPage() {
  const router = useRouter();
  const methods = useForm<Form>({
    defaultValues: {
      country: 'PH',
      phoneNumberPrefix: '+63',
    },
    resolver: yupResolver(formSchema) as unknown as Resolver<Form>,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const { control, handleSubmit: formSubmit, setValue } = methods;

  const { mutate: registerWithPhoneNumber, isLoading } = useRegisterWithPhoneNumber({
    onSuccess: (response) => {
      if (response.action === 'register') {
        setLocalStorageItem(LocalStorageKey.REGISTRATION_OTP_DETAILS, {
          country: response.country,
          countryCode: response.countryCode,
          phoneNumber: response.mobilePhoneNumber,
          timer: response.timer,
          userId: response.mWellUserId,
        });

        router.push({
          pathname: Routes.AUTH_OTP_VERIFICATION,
          query: {
            ...router.query,
            action: 'register',
          },
        });
      } else if (response.action === 'login') {
        setLocalStorageItem(LocalStorageKey.LOGIN_OTP_DETAILS, {
          country: response.country,
          countryCode: response.countryCode,
          phoneNumber: response.mobilePhoneNumber,
          timer: response.timer,
          userId: response.mWellUserId,
        });

        router.push({
          pathname: Routes.AUTH_OTP_VERIFICATION,
          query: {
            ...router.query,
            action: 'login',
          },
        });
      }
    },
    onError: (error) => {
      setErrorMessage(get(error, 'response.data.message', 'Something went wrong'));
    },
  });

  const handleContinue = (data: Form) => {
    if (!data.phoneNumber) {
      setErrorMessage('Mobile number is required.');
      return;
    }

    trackEvent(EventName.CLICK_SEND_SMS_OTP, {
      country: data.country,
      phoneNumber: data.phoneNumber,
      phoneNumberPrefix: data.phoneNumberPrefix,
    });
    registerWithPhoneNumber(data);
  };

  return (
    <>
      <Head>
        <title>Login | HealthHub</title>
        <meta
          name="description"
          content="Log in securely to HealthHub by MWell Philippines to access your personalized health and wellness services. Take control of your well-being journey. Experience seamless access to your appointments, health records, and exclusive features. Join us in prioritizing your health and start your journey with HealthHub today."
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      <div className="w-full">
        <form onSubmit={formSubmit(handleContinue)}>
          <div className="mx-auto flex h-fit w-full flex-col gap-5 rounded-xl bg-neutral-50 px-4 py-5 shadow-authCard md:mt-0 md:w-4/5 md:justify-center md:gap-12 md:px-10 md:py-12">
            <h4 className="text-lg font-semibold text-primary-500 md:text-3xl md:text-neutral-900">
              Welcome to mWell HealthHub!
            </h4>
            <Controller
              name={FormEnum.MOBILE_PHONE_NUMBER}
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <MobileNumberInput
                  showRequiredOnLabel
                  label="Enter mobile number to login or register"
                  errorMessage={error?.message || errorMessage}
                  value={value}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, '');

                    setErrorMessage('');

                    if (!numericValue) {
                      setErrorMessage('Mobile number is required.');
                    }

                    onChange(numericValue);
                  }}
                  onChangeCountry={(countryCode: CountryCodeType) => {
                    setValue(FormEnum.COUNTRY, countryCode.code);
                    setValue(FormEnum.MOBILE_PHONE_NUMBER_PREFIX, countryCode.dial_code);
                  }}
                  onBlur={() => {
                    setErrorMessage('');
                    if (!value) {
                      setErrorMessage('Mobile number is required.');
                    }
                  }}
                />
              )}
            />
            <div>
              <Button
                isDisabled={isLoading}
                isLoading={isLoading}
                type="submit"
                variant="primary"
                className="flex h-[52px] w-full md:h-full"
              >
                Continue
              </Button>

              <div className="mx-auto mt-5 max-w-sm text-center text-sm leading-6 text-neutral-500">
                By proceeding, you agree with{' '}
                <a
                  className="font-semibold text-primary-500"
                  href="https://www.mwell.com.ph/terms-and-conditions"
                  target="_blank"
                  rel="noreferrer"
                >
                  mWell Terms & Conditions
                </a>{' '}
                and{' '}
                <a
                  className="font-semibold text-primary-500"
                  href="https://www.mwell.com.ph/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  mWell Privacy Policy
                </a>
                .
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

AuthPage.getLayout = function getLayout(children: ReactNode) {
  return <AuthLayout shouldHideFooter>{children}</AuthLayout>;
};
